<template>
  <video-player-v1 v-if="liveUrl" :live-url="liveUrl" />
</template>

<script>
import VideoPlayerV1 from './components/VideoPlayerV1.vue'

export default {
  name: 'App',
  components: {
    VideoPlayerV1
  },
  data() {
    return {
      liveUrl: null
    }
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    let liveUrl = urlParams.get('liveUrl');
    if (liveUrl) {
      this.liveUrl = liveUrl;
    }
  }
}
</script>

<style>
body {
  margin: 0;
  background-color: #000;
}
</style>
