<template>
  <div style="display: block;">
    <video ref="videoPlayer" id="video-player" class="video-js vjs-big-play-centered" controls autoplay></video>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import canAutoPlay from 'can-autoplay';

export default {
  name: 'VideoPlayerV1',
  props: {
    liveUrl: String
  },
  data() {
    return {}
  },
  mounted() {
    // Get playback URL from Amazon IVS API
    let PLAYBACK_URL = this.liveUrl;

    // Initialize player
    this.player = videojs(this.$refs.videoPlayer, {
      responsive: true,
      liveui: true,
      controls: true,
      autoplay: true
    }, () => {
      // Play stream
      this.player.src(PLAYBACK_URL);
      canAutoPlay.audio().then(({result}) => {
        if (result === true) {
          this.player.muted(false);
          this.player.play();
        } else {
          this.player.muted(true);
          this.player.play();
        }
      })
    });
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#video-player {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
  text-align: center;
}
</style>
